<template>
  <div class="selects">
    <span>
      55555
      <i class="el-icon el-icon-error"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "Selects"
}
</script>

<style lang="scss" scoped>
  .selects{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    color: #606266;
    padding: .5rem 0;
    span{
      display: inline-block;
      margin-right: .5rem;
      background: #f0f2f5;
      padding: .6rem .8rem;
      border-radius: .2rem;
      .el-icon{
        color: #c0c4cc;
        &:hover{
          color: #999;
        }
      }
    }
  }
</style>
