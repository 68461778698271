<template>
  <div class="selectContent">
    <Selects></Selects>
    <Input/>
  </div>
</template>

<script>
  import {defineComponent} from "vue";
  import Input from "@/components/treeSelected/Input";
  import Selects from "@/components/treeSelected/Selects";
  export default defineComponent({
    name: "SelectContent",
    components:{
      Input,
      Selects
    },
    setup(props, {emit}){

    },
  })
</script>

<style lang="scss" scoped>
  .selectContent{
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
  }
</style>
